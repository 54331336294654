import React, { useState } from 'react'
import './payment.css'
import { initializeApp } from 'firebase/app'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { getFirebaseConfig } from '../lib'
import html2canvas from 'html2canvas'
import Receipt from './receipt.js'

const app = initializeApp(getFirebaseConfig())

export default class WorldnetComplete extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      receipt: false,
      isSubmitting: true,
      isComplete: false
    }
    this.handleEmail = this.handleEmail.bind(this)
  }

  async componentDidMount() {    
    const functions = getFunctions(app)
    const savePayment = httpsCallable(functions, 'onCall-savePayment')
    try {
      const urlSearchParams = new URLSearchParams(window.location.search)
      const res = Object.fromEntries(urlSearchParams.entries())
      console.log(JSON.stringify(res))
      const result = await savePayment({ res })
      console.log('** savePayment', JSON.stringify(result))
      const {
        merchantId,
        refId,
        status,
        orderId,
        firstName,
        lastName,
        address,
        city,
        state,
        zip,
        phone,
        email,
        authCode,
        amountPaid,
        process,
        paymentType,
        last4,
        cardType,
        avsResponse,
        terminalId,
        accountName,
        datetime,
        expire,
        signature,
        amount,
        invoiceRef,
        surchargeFee,
        surchargePercent,
        surchargeMessage,
        isInternal,
        txnStatus,
      } = result.data

      if (isInternal) {
        if (status ===  'Success') {
          this.setState({ 
            isComplete: true,
            alertMessage: null, 
            alertClass: null, 
            completeMessage: 'Approved',
            isSubmitting: false,
            receipt: false,
          })
        } else {
          // if 3 cc tried errors
          if (txnStatus==='Exceeded Attempt Limit') {
              this.setState({ isSubmitting: false, alertMessage: status + `.  You’ve reached the max number of attempts.`, alertClass: 'alert alert-danger alert-fixed' })
          } else this.setState({ isSubmitting: false, alertMessage: status, alertClass: 'alert alert-danger alert-fixed' })
          
        }

        window.top.postMessage(JSON.stringify({
          error: status !==  'Success',
          message: {
            refId,
            status,
            orderId,
            firstName,
            lastName,
            address,
            city,
            state,
            zip,
            phone,
            email,
            authCode,
            amountPaid,
            process,
            paymentType,
            last4,
            cardType,
            avsResponse,
            surchargeFee,
            surchargePercent,
            surchargeMessage,
          }
        }),
          '*'
        )

      } else {
        if (status ===  'Success') {
          this.setState({ 
            refId,
            orderId,
            isSubmitting: false, 
            authCode, 
            alertMessage: null, 
            amountPaid, 
            process,
            paymentType,
            last4,
            cardType,
            avsResponse,
            terminalId,
            accountName,
            datetime,
            expire,
            signature,
            amount,
            invoiceRef,
            merchantId,
            receipt: true, 
            alertClass: null,
            surchargeFee,
            surchargePercent,
            surchargeMessage,
            firstName,
            lastName,
            address,
            city,
            state,
            zip,
          })
        } else {
          // if 3 cc tried errors
          if (txnStatus==='Exceeded Attempt Limit') {
            this.setState({ isSubmitting: false, alertMessage: status + `.  You’ve reached the max number of attempts.`, alertClass: 'alert alert-danger alert-fixed' })
          } else this.setState({ isSubmitting: false, alertMessage: status, alertClass: 'alert alert-danger alert-fixed' })
        }
      }

    } catch(err) {
      console.log('error: ', err.message)
      window.top.postMessage(JSON.stringify({
          error: true,
          message: {
            status: err.message,
          }
        }),
        '*'
      )
      this.setState({ isSubmitting: false, invalid: true, invalidMessage: err.message })
    }
    
  }
  handleEmail({ canvas, email }) {
    const functions = getFunctions(app)
    const emailReceipt = httpsCallable(functions, 'onCall-emailReceipt')
    this.setState({ isSubmitting: true })
    const {
      merchantId,
      terminalId,
      accountName,
      datetime,
      expire,
      refId,
      signature,
      amount,
      orderId,
      invoiceRef,
    } = this.state
    console.log(merchantId, terminalId, amount, datetime, expire, refId, signature, orderId)
    emailReceipt({ merchantId, terminalId, accountName, datetime, expire, refId, signature, amount, emailTo: email, file: canvas, orderId, invoiceRef }).then(result => {
      const {
        status
      } = result.data
      console.log(status)
      if (status ===  'Success') {
        this.setState({ isSubmitting: false, receipt: false, alertMessage: status, alertClass: 'alert alert-success alert-fixed' })
      } else {
        this.setState({ isSubmitting: false, receipt: false, alertMessage: status, alertClass: 'alert alert-danger alert-fixed' })
      }
      // 
    }).catch(err => {
      console.log('error: ', err.message)
      this.setState({ isSubmitting: false, alertMessage: err.message, alertClass: 'alert alert-danger alert-fixed' })
    })
  }

  handleCancelAlert() {
    console.log('cancel alert')
    this.setState({ alertMessage: undefined, alertClass: undefined })
  }

  render() {
    if (this.state.receipt) { // eslint-disable-line no-constant-condition
      return (
        <div>
          { this.state.alertMessage && (
            <div className={this.state.alertClass} role="alert" onClick={this.handleCancelAlert}>
              {this.state.alertMessage}
            </div>
          )}
          { this.state.isSubmitting  && (
            <div id="overlay">
              <div className="spinner"></div>
            </div>
          )}

          { !this.state.palPalComplete && (
          <PaymentReceipt {...this.state} handleEmail={this.handleEmail}/>
          )}

          { this.state.palPalComplete && (
            <PayPalPaymentReceipt {...this.state} handleEmail={this.handleEmail}/>
          )}
        </div>
      )
    }
    if (this.state.invalid) {
      return (
        <div className="App">
          <div className="container-fluid">
            <div className="row justify-content-center m-4">
            <div className="card">
              <div className="card-body">
                <h1>{this.state.invalidMessage}</h1>
              </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    if (this.state.isComplete) {
      return (
        <div className="App">
          <div className="container-fluid">
            <div className="row justify-content-center m-4">
            <div className="card">
              <div className="card-body">
                <h1>{this.state.completeMessage}</h1>
              </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
    if (this.state.isSubmitting) {
      return (
        <div className="App">
          { this.state.alertMessage ? (
          <div className={this.state.alertClass} role="alert" onClick={this.handleCancelAlert}>
            {this.state.alertMessage}
          </div>
        ) : (
          <div className="text-center">
            <div className="spinner-border m-5" style={{ width: 5 + 'rem', height: 5 + 'rem' }} role="status">
            </div>
          </div>
        )}
        </div>
      )
    }
    return (
      <div className="container-fluid">
        { this.state.alertMessage && (
            <div className={this.state.alertClass} role="alert" onClick={this.handleCancelAlert}>
              {this.state.alertMessage}
            </div>
          )}
        <div className="row justify-content-center">
          
        </div>
      </div>
    )
  }
}




function PaymentReceipt(props) {
  const [captureEmail, setCaptureEmail] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [email, setEmail] = useState()
  const handleClick = () => {
    console.log('click')
    if (!captureEmail) {
      setCaptureEmail(true)
    } else if (captureEmail && email) {
      setIsSubmitting(true)
      html2canvas(document.getElementById('root')).then(function(canvas) {
        return props.handleEmail({ canvas: canvas.toDataURL(), email })
      })
    }
  }
  return (
    <div>
      <Receipt {...props} hideHeader={true} />
      <div className="col d-flex justify-content-center">
        <div className="row justify-content-center">
          { captureEmail && (
            <input
            type="email"
            name="email"
            className="form-control mt-1"
            placeholder="Email Address"
            onChange={event => setEmail(event.target.value)}
            data-html2canvas-ignore
            />
          )}
          <button
            type="submit"
            onClick={handleClick}
            className="btn btn-primary mt-1 mb-2"
            data-html2canvas-ignore
            disabled={isSubmitting}
          >
            Email Receipt
          </button>
        </div>
      </div>
    </div>
  )
}

//import venmo from '../images/Venmo3.png'
//{ this.state.hasVenmo && (
//  <img style={{ width: 100 + '%', height: 65 + 'px' }} src={venmo} alt="image" className="img-fluid rounded mt-1"/>
//)}
//import paypal from '../images/PayPal2.png'
// import PayPal from '../components/PayPal2.js'
// import PayPalReceipt from './paypalreceipt.js'

function PayPalPaymentReceipt(props) {
    const [captureEmail, setCaptureEmail] = useState(false)
    const [email, setEmail] = useState()
    const handleClick = () => {
      console.log('paypal receipt click')
      if (!captureEmail) {
        setCaptureEmail(true)
      } else if (captureEmail && email) {
        html2canvas(document.getElementById('root')).then(function(canvas) {
          props.handleEmail({ canvas: canvas.toDataURL(), email })
        })
      }
    }
    /* <PayPalReceipt amountPaid={propData.amount} authCode={authCode} /> */
    return (
      <div>
        <PayPalReceipt {...props}/>
        <div className="col d-flex justify-content-center">
          <div className="row justify-content-center">
            { captureEmail && (
              <input
              type="email"
              name="email"
              className="form-control mt-1"
              placeholder="Email Address"
              onChange={event => setEmail(event.target.value)}
              data-html2canvas-ignore
              />
            )}
            <button
              type="submit"
              onClick={handleClick}
              className="btn btn-primary mt-1 mb-2"
              data-html2canvas-ignore
            >
              Email Receipt
            </button>
          </div>
        </div>
      </div>
    )
  }